<template>
  <div class="page-content">
    <div class="page-content-header">
      <div class="header-box">
        <h1>{{ $t('main_card_1') }}</h1>
        <el-button type="primary" @click="toReg" size="big">{{
          $t('main_card_2')
        }}</el-button>
      </div>
    </div>
    <div class="page-content-body">
      <div class="body-box">
        <h2>{{ $t('main_card_3') }}</h2>
        <div class="body-content">
          <div class="wth280">
            <div class="body-icon"><i class="el-icon-timer"></i></div>
            <div>
              <div class="body-title">{{ $t('main_card_4') }}</div>
              <div class="body-desc">{{ $t('main_card_5') }}</div>
            </div>
          </div>
          <div class="wth280">
            <div class="body-icon"><i class="el-icon-lock"></i></div>
            <div>
              <div class="body-title">{{ $t('main_card_6') }}</div>
              <div class="body-desc">{{ $t('main_card_7') }}</div>
            </div>
          </div>
          <div class="wth280">
            <div class="body-icon"><i class="el-icon-position"></i></div>
            <div>
              <div class="body-title">{{ $t('main_card_8') }}</div>
              <div class="body-desc">{{ $t('main_card_9') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'card',
  methods: {
    toReg() {
      this.$router.push({ name: 'Email' })
    },
  },
}
</script>

<style scoped lang="scss">
.page-content {
  display: flex;
  flex-direction: column;

  .page-content-header {
    background: linear-gradient(135deg, #1a2980, #26d0ce);

    .header-box {
      width: 960px;
      padding: 120px 20px;
      margin: 0 auto;
    }

    h1 {
      color: #ffffff;
    }
  }

  .page-content-body {
    background: #ffffff;
    padding: 30px 0px;

    .body-box {
      width: 960px;
      margin: 0 auto;
      text-align: center;
      padding: 100px 0px;

      .body-content {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        .wth280 {
          width: 280px;
        }

        .body-icon {
          font-size: 40px;
          font-weight: bold;
          margin-top: 20px;
        }

        .body-title {
          font-size: 20px;
          font-weight: bold;
          color: #003d74;
        }

        .body-desc {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
